.is-custom {

  &.accordion {
    padding: rem(4px) rem(8px);

    .accordion {

      &-item {
        border: 0;
      }

      &-header {
  
      }
  
      &-button {
        border-radius: 0;
        padding: rem(16px);
        font-weight: 700;

        &:after {
          background-size: rem(12px);
          background-position: center;
        }

        &:not(.collapsed) {
          color: color(primary);
          background-color: #f6f6f6;
          box-shadow: none;
        }
      }

      &-collapse {

      }

      &-body {
        padding: rem(16px);
        padding-top: 0;
        background-color: #f6f6f6;
      }
    }
  }
}