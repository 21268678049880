.table {

  &-primary {
    position: relative;
    z-index: 1;

    th,
    td {
      padding: rem(2px + 4px) rem(2px + 8px);
    }

    th {
      position: relative;
      color: white;
      font-size: rem(18px);
      font-weight: 700;

      &:before {
        position: absolute;
        z-index: -1;
        display: block;
        content: "";
        top: rem(2px);
        bottom: rem(2px);
        left: rem(2px);
        right: rem(2px);
        background-color: color(primary);
        border-radius: rem(4px);
      }
    }

    td.price {
      position: relative;
      padding: rem(2px + 8px) rem(2px + 8px);
      text-align: center;
      color: color(primary);
      font-size: rem(17px);

      &:before,
      &:after {
        display: block;
        content: "";
        position: absolute;
        left: rem(2px);
        right: rem(2px);
        height: 1px;
        background-color: color(primary);
      }

      &:before {
        top: rem(2px);
      }

      &:after {
        bottom: rem(2px);
      }
    }
  }

  &-thbg {

    th {
      background-color: #fafafa;
    }
  }

  &-responsive {
    white-space: nowrap;
  }
}