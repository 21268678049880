.jumbotron {

  &-link {
    padding: rem(24px) 0;

    > .title {
      font-weight: normal;
      margin-bottom: rem(10px);

      @include breakpoints(xs) {
        font-size: rem(20px);
      }

      @include breakpoints(sm) {
        font-size: rem(26px);
      }

      @include breakpoints(md) {
        font-size: rem(32px);
      }

      @include breakpoints(lg) {
        font-size: rem(38px);
      }

      @include breakpoints(xl) {
        font-size: rem(44px);
      }

      @include breakpoints(xxl) {
        font-size: rem(50px);
      }
    }

    > .text {
      font-size: rem(24px);
      margin-bottom: 0;

      @include breakpoints(xs) {
        font-size: rem(18px);
      }

      @include breakpoints(sm) {
        font-size: rem(19px);
      }

      @include breakpoints(md) {
        font-size: rem(20px);
      }

      @include breakpoints(lg) {
        font-size: rem(21px);
      }

      @include breakpoints(xl) {
        font-size: rem(22px);
      }

      @include breakpoints(xxl) {
        font-size: rem(23px);
      }
    }
    
    > .link {

      > a {
        display: inline-block;
        font-size: rem(72px);
        font-weight: 100;
        margin-right: 0;
        font-style: normal;
        color: inherit;
        text-decoration: none;

        &:first-child {
          margin-left: 0;
        }

        &:after {
          display: block;
          content: "";
          width: 100%;
          height: 10px;
          background-color: color(primary);
        }

        @include breakpoints(xs) {
          font-size: rem(27px);
          margin-left: rem(20px);
        }

        @include breakpoints(sm) {
          font-size: rem(36px);
          margin-left: rem(26px);
        }

        @include breakpoints(md) {
          font-size: rem(45px);
          margin-left: rem(32px);
        }

        @include breakpoints(lg) {
          font-size: rem(54px);
          margin-left: rem(38px);
        }

        @include breakpoints(xl) {
          font-size: rem(65px);
          margin-left: rem(44px);
        }

        @include breakpoints(xxl) {
          font-size: rem(72px);
          margin-left: rem(50px);
        }
      }
    }
  }
}