.subject {
  padding: rem(70px) 0;
  word-break: keep-all;

  h1,
  h2 {
    font-family: "YES24", sans-serif;
    margin-bottom: rem(24px);

    &:last-child {
      margin-bottom: 0;
    }
  }

  h3,
  h4 {
    margin-bottom: rem(6px);

    &:last-child {
      margin-bottom: 0;
    }
  }

  h1 {

    @include breakpoints(xs) {
      font-size: rem(28px);
    }

    @include breakpoints(lg) {
      font-size: rem(38px);
    }

    @include breakpoints(xl) {
      font-size: rem(48px);
    }
  }

  h2 {

    @include breakpoints(xs) {
      font-size: rem(20px);
    }

    @include breakpoints(lg) {
      font-size: rem(22px);
    }

    @include breakpoints(xl) {
      font-size: rem(24px);
    }
  }

  h3 {
    font-weight: 700;

    @include breakpoints(xs) {
      font-size: rem(18px);
    }

    @include breakpoints(lg) {
      font-size: rem(19px);
    }

    @include breakpoints(xl) {
      font-size: rem(20px);
    }
  }

  h4 {
    font-size: inherit;
    font-weight: 700;
  }

  &-nav {

    &,
    &-jumbo,
    &-detail {

      ul {
        padding: 0;
        margin: 0;
        list-style: none;

        li {

          a {
            color: inherit;
            text-decoration: none;
          }
        }
      }

      >ul {
        display: flex;
        flex-wrap: wrap;
      }
    }

    & {

      >ul {
        justify-content: center;

        @include breakpoints(xs) {
          margin: rem(-8px);
        }

        @include breakpoints(sm) {
          margin: rem(-10px);
        }

        @include breakpoints(lg) {
          margin: rem(-12px);
        }

        >li {

          @include breakpoints(xs) {
            padding: rem(8px);
          }

          @include breakpoints(sm) {
            padding: rem(10px);
          }

          @include breakpoints(lg) {
            padding: rem(12px);
          }

          >a {

            @include breakpoints(xs) {
              font-size: rem(16px);
            }

            @include breakpoints(sm) {
              font-size: rem(18px);
            }

            @include breakpoints(lg) {
              font-size: rem(20px);
            }
          }
        }
      }
    }

    &-jumbo {

      >ul {
        justify-content: flex-start;

        @include breakpoints(xs) {
          margin: rem(-5px) rem(-10px);
        }

        @include breakpoints(sm) {
          margin: rem(-10px) rem(-15px);
        }

        @include breakpoints(lg) {
          margin: rem(-15px) rem(-20px);
        }

        >li {

          @include breakpoints(xs) {
            padding: rem(5px) rem(10px);
          }

          @include breakpoints(sm) {
            padding: rem(10px) rem(15px);
          }

          @include breakpoints(lg) {
            padding: rem(15px) rem(20px);
          }

          >a {
            color: color(primary);
            font-weight: 700;

            @include breakpoints(xs) {
              font-size: rem(18px);
            }

            @include breakpoints(sm) {
              font-size: rem(25px);
            }

            @include breakpoints(lg) {
              font-size: rem(32px);
            }
          }
        }
      }
    }

    &,
    &-jumbo {
      overflow: hidden;

      >ul {
        flex-grow: 0;
        flex-shrink: auto;

        >li {

          >a {
            display: block;
            position: relative;
            padding: rem(8px) 0;

            &:after {
              display: block;
              content: "";
              position: absolute;
              left: 0;
              right: 0;
              bottom: 0;
              height: 1px;
              background-color: color(primary);
            }

            &:hover {
              font-weight: 700;

              &:after {
                height: 2px;
              }
            }
          }
        }
      }
    }

    &-detail {

      >ul {
        margin: rem(-20px);

        >li {
          padding: rem(20px);

          @include breakpoints(xs) {
            width: percentage(1/1);
          }

          @include breakpoints(sm) {
            width: percentage(1/2);
          }

          @include breakpoints(lg) {
            width: percentage(1/3);
          }

          @include breakpoints(xl) {
            width: percentage(1/4);
          }

          >a {
            font-size: 20px;
            font-weight: 700;
          }

          >ul {
            margin-top: rem(10px);

            >li {
              padding: rem(5px) 0;
            }
          }
        }
      }
    }
  }
}