.widget {

  .title {
    padding: 0 rem(16px) rem(40px) rem(16px);

    > h2 {
      font-size: rem(24px);
      font-weight: 700;

      > a {
        color: inherit;
        text-decoration: none;
      }
    }

    > p {
      padding: 0;
      margin: 0;
    }
  }

  .items {
    margin-top: rem(10px);

    > ul {
      padding: 0;
      margin: 0;
      list-style: none;

      > li {
        padding: rem(4px) rem(8px);

        > a {
          display: block;
          position: relative;
          font-size: rem(14px);
          line-height: rem(21px);
          color: inherit;
          text-decoration: none;
          padding: rem(4px) rem(8px);
          transition: background-color 0.3s;

          &:hover{
            background-color: #f5f5f5;
          }

          > em {
            display: block;
            font-style: normal;
            font-weight: 700;
            width: calc(100% - 16px);
            
            @include ellipsis(1);
          }

          > span {
            display: block;
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            width: rem(21px);
            height: rem(21px);
            font-weight: 700;
            text-align: center;
          }
        }
      }
    }
  }
}