.l {

  &-footer {
    font-size: rem(12px);
    font-family: 'Yes Gothic', sans-serif;
    color: black;
    border-top: 1px solid $global-border-color;
    border-bottom: 1px solid $global-border-color;
    margin-top: auto;

    >.container {
      position: relative;
      flex-wrap: wrap;
      padding-top: $header-padding-spacing;
      padding-bottom: $header-padding-spacing;

      @include breakpoints(xs) {
        display: block;
      }

      @include breakpoints(xl) {
        display: flex;
      }
    }

    &-logo {
      margin-right: rem(24px);

      @include breakpoints(xs) {
        margin-bottom: rem(24px);
      }

      @include breakpoints(xl) {
        margin-bottom: 0;
      }
    }

    &-copyright {

      >address {
        padding: 0;
        margin: 0;
        overflow: hidden;

        >dl {
          display: flex;
          flex-wrap: wrap;
          padding: 0;
          margin: 0;
          margin-bottom: rem(6px);
          margin-left: rem(-18px);

          >dt {
            display: none;
          }

          >dd {
            padding: 0;
            margin: 0;

            &:before {
              display: inline-block;
              content: "|";
              width: rem(18px);
              text-align: center;
              padding: 0 $spacer / 4;
            }
          }
        }
      }

      >p {
        margin-bottom: 0;
      }
    }

    &-nav {

      @include breakpoints(xs) {
        margin-top: rem(16px);
        margin-left: 0;
      }

      @include breakpoints(xl) {
        margin-top: 0;
        margin-left: auto;
      }

      >ul {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin: 0;
        list-style: none;

        >li {
          padding: 0;
          margin: 0;

          >a {
            text-decoration: none;
            color: inherit;
          }

          &:after {
            display: inline-block;
            content: "|";
            width: rem(18px);
            text-align: center;
            padding: 0 $spacer / 4;
          }

          &:last-child {

            &:after {
              display: none;
            }
          }
        }
      }
    }
  }
}