.horizontal {
  border-color: $global-border-color !important;
  opacity: 1;
  margin-top: 0;
  margin-bottom: 0;

  @include breakpoints(xs) {
    margin-left: rem(-22px);
    margin-right: rem(-22px);
  }

  @include breakpoints(md) {
    margin-left: rem(-32px);
    margin-right: rem(-32px);
  }

  @include breakpoints(xl) {
    margin-left: rem(-40px);
    margin-right: rem(-40px);
  }

  &.is-left {

    .horizontal {

      @include breakpoints(lg) {
        margin-right: 0;
      }
    }
  }

  &.is-right {

    .horizontal {

      @include breakpoints(lg) {
        margin-left: 0;
      }
    }
  }
}