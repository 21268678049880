.contacts {

  &-image {
    padding: rem(30px) 0;

    img {
      max-width: 100%;
    }
  }

  &-hour {

    .title {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      padding-top: rem(24px);
      padding-bottom: rem(24px);

      &:after {
        content: "";
        position: absolute;
        background-color: $global-border-color;

        @include breakpoints(xs) {
          top: auto;
          bottom: 0;
          left: 0 + ($grid-gutter-width / 2) - rem(16px);
          right: 0 + ($grid-gutter-width / 2) - rem(16px);
          width: auto;
          height: 1px;
        }

        @include breakpoints(md) {
          top: auto;
          bottom: 0;
          left: 0 + ($grid-gutter-width / 2) - rem(32px);
          right: 0 + ($grid-gutter-width / 2) - rem(32px);
          width: auto;
          height: 1px;
        }

        @include breakpoints(lg) {
          top: rem(24px);
          bottom: rem(24px);
          left: auto;
          right: 0;
          width: 1px;
          height: auto;
        }
      }

      >.text {
        margin-bottom: rem(24px);

        >h2 {
          font-family: "YES24", sans-serif;

          @include breakpoints(xs) {
            font-size: rem(30px);
          }

          @include breakpoints(sm) {
            font-size: rem(36px);
          }

          @include breakpoints(md) {
            font-size: rem(42px);
          }

          @include breakpoints(lg) {
            font-size: rem(48px);
          }

          @include breakpoints(xl) {
            font-size: rem(56px);
          }
        }

        >p {

          @include breakpoints(xs) {
            font-size: rem(16px);
          }

          @include breakpoints(md) {
            font-size: rem(18px);
          }

          @include breakpoints(lg) {
            font-size: rem(20px);
          }
        }
      }

      >.description {

        @include breakpoints(xs) {
          font-size: rem(16px);
        }

        @include breakpoints(md) {
          font-size: rem(18px);
        }

        @include breakpoints(lg) {
          font-size: rem(20px);
        }

        >dl {
          display: flex;
          padding: 0;
          margin: 0;

          >dt {
            margin-right: rem(16px);
          }
        }
      }
    }

    .week {
      display: flex;
      align-items: center;

      @include breakpoints(xs) {
        padding-top: 0;
        padding-bottom: 0;
      }

      @include breakpoints(lg) {
        padding-top: rem(24px);
        padding-bottom: rem(24px);
      }

      >div {
        width: 100%;

        @include breakpoints(xs) {
          padding-left: 0;
        }

        @include breakpoints(lg) {
          padding-left: rem(12px);
        }

        >ul {
          display: flex;
          flex-wrap: wrap;
          list-style: none;
          padding: 0;
          margin: 0;

          >li {
            position: relative;
            padding: rem(24px) 0;

            @include breakpoints(xs) {
              width: percentage(1 / 2);
            }

            @include breakpoints(sm) {
              width: percentage(1 / 3);
            }

            &:after {
              position: absolute;
              display: block;
              content: "";
              height: 1px;
              background-color: $global-border-color;
              bottom: -1px;
              left: 0;
              right: 0;
            }

            @include breakpoints(xs) {

              &:nth-last-child(1):after,
              &:nth-last-child(2):after {
                display: none;
              }

              &:nth-last-child(3):after {
                display: block;
              }
            }

            @include breakpoints(sm) {

              &:nth-last-child(1):after,
              &:nth-last-child(2):after,
              &:nth-last-child(3):after {
                display: none;
              }
            }

            &:nth-child(2n + 1):after {

              @include breakpoints(xs) {
                left: rem(-16px);
              }

              @include breakpoints(md) {
                left: rem(-32px);
              }

              @include breakpoints(lg) {
                left: 0;
              }
            }

            &:nth-child(2n + 2):after {

              @include breakpoints(xs) {
                right: rem(-16px);
              }

              @include breakpoints(sm) {
                right: 0;
              }
            }

            &:nth-child(3n + 3):after {

              @include breakpoints(xs) {
                right: 0;
              }

              @include breakpoints(sm) {
                right: rem(-16px);
              }

              @include breakpoints(md) {
                right: rem(-32px);
              }

              @include breakpoints(xl) {
                right: rem(-40px);
              }
            }

            >dl {
              padding: 0;
              margin: 0;

              @include breakpoints(xs) {
                font-size: rem(16px);
              }

              @include breakpoints(md) {
                font-size: rem(20px);
              }

              @include breakpoints(md) {
                font-size: rem(18px);
              }

              @include breakpoints(xl) {
                font-size: rem(20px);
              }

              @include breakpoints(xxl) {
                font-size: rem(24px);
              }
            }
          }
        }
      }
    }
  }

  &-direction {

    @include breakpoints(xs) {
      padding: rem(40px) 0;
    }

    @include breakpoints(md) {
      padding: rem(60px) 0;
    }

    @include breakpoints(lg) {
      padding: rem(80px) 0;
    }

    >h2 {
      font-family: "YES24", sans-serif;

      @include breakpoints(xs) {
        font-size: rem(30px);
      }

      @include breakpoints(sm) {
        font-size: rem(36px);
      }

      @include breakpoints(md) {
        font-size: rem(42px);
      }

      @include breakpoints(lg) {
        font-size: rem(48px);
      }

      @include breakpoints(xl) {
        font-size: rem(56px);
      }
    }

    >p {

      @include breakpoints(xs) {
        font-size: rem(16px);
      }

      @include breakpoints(sm) {
        font-size: rem(20px);
      }

      @include breakpoints(md) {
        font-size: rem(24px);
      }

      @include breakpoints(lg) {
        font-size: rem(28px);
      }

      @include breakpoints(xl) {
        font-size: rem(32px);
      }

      >span {
        margin-right: rem(16px);
      }
    }

    .iframe {
      position: relative;
      margin-top: 1rem;

      &:before {
        display: block;
        content: "";
        padding-bottom: percentage(500 / 700);
      }

      >iframe {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }

    .image {
      margin-top: 1rem;
      background-image: url(../../images/contacts-direction.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;

      &:before {
        display: block;
        content: "";
        padding-bottom: percentage(500 / 700);
      }
    }
  }
}