.gallery {
  overflow: hidden;

  >ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: (0 - ($gallery-gutter-y / 2)) (0 - ($gallery-gutter-x / 2));
    list-style: none;

    >li {
      position: relative;
      padding: ($gallery-gutter-y / 2) ($gallery-gutter-x / 2);

      >a {
        display: block;
        color: inherit;
        text-decoration: none;

        >.thumb {
          position: relative;
          overflow: hidden;
          padding-bottom: percentage(240 / 432);
          margin-bottom: rem(16px);

          >img {
            position: absolute;
            width: 100%;
            min-height: 100%;
            top: -9999px;
            bottom: -9999px;
            margin: auto;
          }
        }

        >.meta {
          overflow: hidden;

          >dl {
            display: flex;
            padding: 0;
            margin: 0 rem(-8px);

            >dt {
              display: none;
            }

            >dd {
              position: relative;
              display: block;
              padding: 0 rem(8px);
              margin: 0;

              &:before {
                position: absolute;
                content: "|";
                left: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                transform: translateX(-50%);
              }

              &:first-child:before {
                display: none;
              }
            }
          }
        }

        >.title {
          display: block;
          font-size: rem(24px);
          font-weight: 700;
          font-style: normal;
          margin-bottom: rem(8px);

          @include ellipsis(1);
        }

        >.summary {
          @include ellipsis(1);
        }
      }

      &:before {
        display: block;
        content: "";
        width: 1px;
        position: absolute;
        background-color: $global-border-color;
        left: -0.5px;
        top: ($gallery-gutter-y / 2);
        bottom: ($gallery-gutter-y / 2);
      }

      &[data-thumb="false"] {

        >a {

          >.thumb {
            display: none;
          }

          >.title {
            font-weight: normal;
            @include ellipsis(4, 1.25em);

            @include breakpoints(xs) {
              font-size: rem(32px);
            }

            @include breakpoints(md) {
              font-size: rem(40px);
            }

            @include breakpoints(xl) {
              font-size: rem(48px);
            }
          }
        }
      }
    }
  }

  &[data-col="1"] {

    >ul {

      >li {
        width: 100%;
      }
    }
  }

  &[data-col="2"] {

    >ul {

      >li {

        @include breakpoints(xs) {
          width: 100%;
        }

        @include breakpoints(md) {
          width: percentage(1 / 2);
        }

        @include breakpoints(xl) {
          width: percentage(1 / 2);
        }
      }
    }
  }

  &[data-col="3"] {

    >ul {

      >li {

        @include breakpoints(xs) {
          width: 100%;
        }

        @include breakpoints(md) {
          width: percentage(1 / 2);
        }

        @include breakpoints(xl) {
          width: percentage(1 / 3);
        }
      }
    }
  }

  &[data-col="4"] {

    >ul {

      >li {

        @include breakpoints(xs) {
          width: 100%;
        }

        @include breakpoints(md) {
          width: percentage(1 / 2);
        }

        @include breakpoints(xl) {
          width: percentage(1 / 3);
        }

        @include breakpoints(xxl) {
          width: percentage(1 / 4);
        }
      }
    }
  }

  &[data-firstemphasis="true"] {

    >ul {

      >li {

        &:first-child {
          width: 100% !important;

          >a {

            >.thumb {

              @include breakpoints(xxl) {
                padding-bottom: percentage(320 / 918);
              }

              @include breakpoints(xs) {
                padding-bottom: percentage(240 / 432);
              }
            }
          }
        }
      }
    }
  }

  &.is-latest {
    padding: rem(40px) 0;

    >ul {

      >li {

        >a {

          >.summary {
            display: none;
          }
        }
      }
    }

    &[data-col="2"][data-firstemphasis="true"] {

      >ul {

        >li {

          >a {

            .thumb {


              @include breakpoints(xl) {
                padding-bottom: percentage(514 / 1400);
              }
            }
          }
        }
      }
    }
  }

  &.is-recent {
    margin: rem(40px);

    >ul {

      >li {

        >a {

          >.summary {
            display: none;
          }
        }
      }
    }
  }
}