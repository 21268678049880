.nav {

  &-justified {

    .nav {

      &-item {
        white-space: nowrap;
      }
    }
  }

  &-default {
    border-bottom: 0;
    margin-bottom: 1.5rem;

    .nav {

      &-link,
      &-link.active,
      &-item.show &-link {
        border-color: darken(color(light), 10%);
      }

      &-link {
        color: lighten(color(dark), 20%);
        background-color: darken(color(light), 1%);
        border-radius: 0;
        position: relative;
        margin: 0 -0.5px;
        padding: 1rem;
        transition: 0.3s;

        @include breakpoints(xs) {
          font-size: 0.825rem;
        }

        @include breakpoints(sm) {
          font-size: 1rem;
        }

        @include breakpoints(md) {
          font-size: 1.125rem;
        }

        &:before {
          display: block;
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          height: 4px;
          background-color: darken(color(light), 1%);
          transition: 0.3s;
        }

        &.active {
          color: color(primary);

          &:before {
            background-color: color(primary);
          }
        }
      }
    }
  }
}