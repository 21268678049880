.channelk {

  &-nav {
    display: flex;
    justify-content: space-between;

    @include breakpoints(xs) {
      padding: rem(40px) 0;
      flex-direction: column;
      align-items: baseline;
    }

    @include breakpoints(sm) {
      padding: rem(60px) 0;
    }

    @include breakpoints(xxl) {
      flex-direction: row;
      align-items: flex-end;
    }

    .title {

      >h1 {
        font-family: "Museo Sans Rounded", sans-serif;

        @include breakpoints(xs) {
          font-size: rem(40px);
        }

        @include breakpoints(sm) {
          font-size: rem(60px);
        }

        @include breakpoints(md) {
          font-size: rem(80px);
        }

        @include breakpoints(lg) {
          font-size: rem(100px);
        }

        @include breakpoints(xl) {
          font-size: rem(120px);
        }
      }

      >p {

        @include breakpoints(xs) {
          font-size: rem(16px);
        }

        @include breakpoints(sm) {
          font-size: rem(20px);
        }

        @include breakpoints(md) {
          font-size: rem(24px);
        }

        @include breakpoints(lg) {
          font-size: rem(28px);
        }

        @include breakpoints(xl) {
          font-size: rem(32px);
        }
      }
    }

    .filter {
      display: flex;
      justify-content: space-between;

      @include breakpoints(xs) {
        flex-direction: column;
        width: 100%;
      }

      @include breakpoints(xl) {
        flex-direction: row;
      }

      @include breakpoints(xxl) {
        flex-direction: column;
        width: auto;
      }
    }

    .nav {
      overflow: hidden;
      margin-top: rem(24px);

      >ul {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        list-style: none;

        @include breakpoints(xs) {
          margin: rem(-2px);
        }

        @include breakpoints(md) {
          margin: rem(-8px);
        }

        >li {
          margin: 0;

          @include breakpoints(xs) {
            padding: rem(2px);
          }

          @include breakpoints(md) {
            padding: rem(8px);
          }

          >a {
            display: block;
            color: inherit;
            text-decoration: none;
            border: 1px solid $global-border-color;
            border-radius: $border-radius-pill;

            @include breakpoints(xs) {
              font-size: rem(13px);
              padding: rem(6px) rem(10px);
            }

            @include breakpoints(xs) {
              font-size: rem(14.5px);
              padding: rem(7px) rem(13px);
            }

            @include breakpoints(md) {
              font-size: rem(16px);
              padding: rem(8px) rem(16px);
            }
          }

          &.is-active {

            >a {
              background-color: color(primary);
            }
          }
        }
      }
    }
  }

  &-video {
    padding: rem(50px) 0;

    .title {
      margin: 0 0 rem(32px) 0;
      word-break: keep-all;

      @include breakpoints(xs) {
        font-size: rem(32px);
      }

      @include breakpoints(sm) {
        font-size: rem(38px);
      }

      @include breakpoints(md) {
        font-size: rem(44px);
      }

      @include breakpoints(lg) {
        font-size: rem(50px);
      }

      @include breakpoints(xl) {
        font-size: rem(56px);
      }
    }

    .iframe {
      position: relative;

      &:before {
        display: block;
        content: "";
        padding-bottom: percentage(720 / 1280);
      }

      >iframe {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
    }
  }

  &-blog {

    >.meta {
      text-align: center;
      margin: rem(32px) 0;

      >div {
        display: inline-block;
        overflow: hidden;

        >dl {
          display: flex;
          padding: 0;
          margin: 0 rem(-8px);

          >dt {
            display: none;
          }

          >dd {
            position: relative;
            display: block;
            padding: 0 rem(8px);
            margin: 0;

            &:before {
              position: absolute;
              content: "|";
              left: 0;
              top: 0;
              bottom: 0;
              margin: auto;
              transform: translateX(-50%);
            }

            &:first-child:before {
              display: none;
            }
          }
        }
      }
    }

    >.thumb {
      position: relative;
      overflow: hidden;
      margin: rem(32px) 0;
      max-height: 500px;

      &:before {
        display: block;
        content: "";
        padding-bottom: percentage(500 / 960);
      }

      >img {
        position: absolute;
        left: 0;
        right: 0;
        top: -9999px;
        bottom: -9999px;
        width: 100%;
        min-height: 100%;
        margin: auto;
      }
    }

    >.title {
      word-break: keep-all;
      margin: rem(32px) 0;

      @include breakpoints(xs) {
        font-size: rem(32px);
      }

      @include breakpoints(sm) {
        font-size: rem(38px);
      }

      @include breakpoints(md) {
        font-size: rem(44px);
      }

      @include breakpoints(lg) {
        font-size: rem(50px);
      }

      @include breakpoints(xl) {
        font-size: rem(56px);
      }
    }

    >.content {
      margin: rem(32px) 0;
    }
  }

  &-video,
  &-blog {

    .content {

      img {
        max-width: 100%;
      }
    }

    .profile {

      >figure {
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;

        @include breakpoints(xs) {
          flex-direction: column;
        }

        @include breakpoints(sm) {
          flex-direction: row;
        }

        >div {

          >img {
            display: block;
          }
        }

        >figcaption {

          @include breakpoints(xs) {
            margin-top: rem(12px);
            margin-left: 0;
            text-align: center;
          }

          @include breakpoints(sm) {
            margin-top: 0;
            margin-left: rem(20px);
            text-align: left;
          }

          >em {
            display: block;
            font-size: rem(18px);
            font-weight: 700;
            font-style: normal;
          }

          >span {
            display: block;
            font-size: rem(14px);
          }
        }
      }
    }

    .buttons {

      @include breakpoints(xs) {
        margin: rem(32px) auto auto auto;
      }

      @include breakpoints(sm) {
        margin: 0;
      }

      .btn {
        font-size: rem(14px);
        padding: rem(6px) rem(24px);
      }
    }

    .control {
      display: flex;
      margin: rem(56px) 0 rem(70px) 0;
      align-items: center;

      @include breakpoints(xs) {
        flex-direction: column;
        justify-content: flex-start;
      }

      @include breakpoints(sm) {
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }

  .attach {
    overflow: hidden;
    margin-top: rem(20px);

    &-title {
      font-size: 1.25rem;
      margin-bottom: 1rem;
    }

    >ul {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      margin: -0.5rem;
      list-style: none;

      >li {
        padding: 0.5rem;

        >a {
          display: block;
          text-decoration: none;
          color: color(dark);
          font-size: 0.875rem;
          border-width: $border-width;
          border-style: $border-style;
          border-color: $border-color;
          background-color: white;
          padding: 0.5rem;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &-search {

    @include breakpoints(xs) {
      margin-top: rem(24px);
    }

    @include breakpoints(xl) {
      margin-top: 0;
    }

    @include breakpoints(xxl) {
      margin-top: rem(24px);
    }

    .search {
      width: 100%;
      display: flex;
      border: 1px $border-style color(primary);
      border-radius: $border-radius-pill;
      padding: rem(9px);

      >input,
      >button {
        display: block;
        background: none;
        border: none;
        box-sizing: border-box;
        outline: 0;

        @include breakpoints(xs) {
          font-size: rem(16px);
          min-width: rem(30px);
          height: rem(30px);
        }

        @include breakpoints(sm) {
          font-size: rem(20px);
          min-width: rem(40px);
          height: rem(40px);
        }
      }

      >input[name="keyword"] {
        flex-grow: 1;
        flex-basis: auto;
        padding-left: rem(16px);

        @include breakpoints(xs) {
          width: calc(100% - (rem(30px) * 2));
        }

        @include breakpoints(sm) {
          width: calc(100% - (rem(40px) * 2));
        }
      }

      >button {
        flex-grow: 0;
        flex-basis: auto;
        color: color(primary);
        transition: 0.3s;

        &[disabled] {
          opacity: 0.3;
        }
      }
    }
  }
}