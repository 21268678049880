.arrows {
  font-family: "SUIT", sans-serif;
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  padding: rem(12px) 0;
  margin: rem(50px) 0;
  border-top: 1px solid $global-border-color;
  border-bottom: 1px solid $global-border-color;

  > a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: rem(12px);
    color: inherit;
    text-decoration: none;
    margin: 0 rem(-12px);
    white-space: nowrap;

    > em, > span {
      display: block;
      padding: 0 rem(12px);
      font-style: normal;
    }

    > em {
      text-align: center;
      font-weight: 700;

      > i {
        font-style: inherit;
      }
    }

    > span {
      width: calc(100% - rem(90px));
      font-size: rem(14px);

      @include ellipsis(1);

      @include breakpoints(xs) {
        display: none;
      }

      @include breakpoints(sm) {
        display: block;
      }
    }
  }

  .arrows{

    &-prev, &-next {
      flex-grow: 1;

      @include breakpoints(xs) {
        max-width: auto;
      }

      @include breakpoints(sm) {
        max-width: 300px;
      }
    }

    &-prev {
      flex-direction: row-reverse;
    }

    &-next {
      flex-direction: row;
    }

    &:first-child {
      margin-bottom: rem(32px);
    }

    &:last-child {
      margin-top: rem(70px);
    }
  }
}