.about {

  &-opening {
    padding-top: rem(100px);

    @include breakpoints(xs) {
      text-align: left;
    }

    @include breakpoints(md) {
      text-align: center;
    }

    >h1 {
      font-family: "YES24", sans-serif;
      word-break: keep-all;

      @include breakpoints(xs) {
        font-size: rem(24px);
        margin-bottom: rem(30px);
      }

      @include breakpoints(sm) {
        font-size: rem(27px);
      }

      @include breakpoints(md) {
        font-size: rem(36px);
        margin-bottom: rem(56px);
      }

      @include breakpoints(lg) {
        font-size: rem(45px);
      }

      @include breakpoints(xl) {
        font-size: rem(54px);
      }

      >br {

        @include breakpoints(xs) {
          display: none;
        }

        @include breakpoints(sm) {
          display: inline;
        }
      }
    }

    >p {
      font-family: "YES Gothic", sans-serif;

      @include breakpoints(xs) {
        margin-bottom: rem(50px);
      }

      @include breakpoints(md) {
        margin-bottom: rem(100px);
      }

      >br {

        @include breakpoints(xs) {
          display: none;
        }

        @include breakpoints(lg) {
          display: inline;
        }
      }
    }

    >img {
      display: block;
      width: 100%;
    }
  }

  &-philosophy {

    >ul {
      padding: 0;
      margin: 0;
      list-style: none;

      >li {
        padding: rem(40px) 0;
      }
    }

    .wrap {

      @include breakpoints(xs) {
        display: block;
      }

      @include breakpoints(md) {
        display: flex;
      }
    }

    .thumb {
      position: relative;
      min-height: 278px;
      overflow: hidden;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

      @include breakpoints(xs) {
        width: auto;
        margin-bottom: rem(24px);
      }

      @include breakpoints(md) {
        width: 40%;
        margin-bottom: 0;
      }

      /*
      >img {
        position: absolute;
        left: -9999px;
        right: -9999px;
        top: 0;
        bottom: 0;
        margin: auto;
      }
      */

      &:before {
        padding-bottom: percentage(411 / 446);
      }

      &.thumb {

        &-01 {
          background-image: url(../../images/about-philosophy01.jpg);
        }

        &-02 {
          background-image: url(../../images/about-philosophy02.jpg);
        }

        &-03 {
          background-image: url(../../images/about-philosophy03.jpg);
        }

        &-04 {
          background-image: url(../../images/about-philosophy04.jpg);
        }

        &-05 {
          background-image: url(../../images/about-philosophy05.jpg);
        }

        &-06 {
          background-image: url(../../images/about-philosophy06.jpg);
        }
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      margin-left: auto;

      @include breakpoints(xs) {
        width: auto;
      }

      @include breakpoints(md) {
        width: calc(100% - 40% - rem(40px));
      }

      >h4 {
        font-family: "YES24", sans-serif;
        margin-bottom: rem(24px);
        word-break: keep-all;

        @include breakpoints(xs) {
          font-size: rem(24px);
        }

        @include breakpoints(sm) {
          font-size: rem(30px);
        }

        @include breakpoints(md) {
          font-size: rem(24px);
        }

        @include breakpoints(lg) {
          font-size: rem(22px);
        }

        @include breakpoints(xl) {
          font-size: rem(30px);
        }

        @include breakpoints(xxl) {
          font-size: rem(36px);
        }
      }

      >div {
        margin-top: auto;
      }
    }
  }

  &-button {}

  &-gallery {

    .title {
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      >h2 {
        font-family: "YES24", sans-serif;
        word-break: keep-all;

        @include breakpoints(xs) {
          font-size: rem(24px);
        }

        @include breakpoints(md) {
          font-size: rem(37px);
        }

        @include breakpoints(xxl) {
          font-size: rem(46px);
        }
      }
    }

    .img-box {
      background-position: center;
      background-size: cover;

      &_01 {
        background-image: url(../../images/about-gallery01.jpg);
      }

      &_02 {
        background-image: url(../../images/about-gallery02.jpg);
      }

      &_03 {
        background-image: url(../../images/about-gallery03.jpg);
      }

      &_04 {
        background-image: url(../../images/about-gallery04.jpg);
      }

      &_05 {
        background-image: url(../../images/about-gallery05.jpg);
      }

      &_06 {
        background-image: url(../../images/about-gallery06.jpg);
      }

      &_07 {
        background-image: url(../../images/about-gallery07.jpg);
      }

      &_08 {
        background-image: url(../../images/about-gallery08.jpg);
      }

      &_09 {
        background-image: url(../../images/about-gallery09.jpg);
      }

      &_10 {
        background-image: url(../../images/about-gallery10.jpg);
      }

      &_11 {
        background-image: url(../../images/about-gallery11.jpg);
      }
    }

    .grid {
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: 580px 310px 250px 100px 350px 600px 100px 340px;
      gap: rem(16px);

      @include breakpoints(xs) {
        display: block;
      }

      @include breakpoints(xl) {
        display: grid;
      }

      >.title {
        grid-column: 1 / 4;
        grid-row: 1 / 2;
      }

      >.img-box {

        @include breakpoints(xs) {
          display: none;
        }

        @include breakpoints(xl) {
          display: block;
        }

        &_01 {
          grid-column: 4 / 13;
          grid-row: 1 / 2;
        }

        &_02 {
          grid-column: 1 / 5;
          grid-row: 2 / 4;
        }

        &_03 {
          grid-column: 1 / 5;
          grid-row: 4 / 6;
        }

        &_04 {
          grid-column: 5 / 13;
          grid-row: 2 / 3;
        }

        &_05 {
          grid-column: 5 / 9;
          grid-row: 3 / 6;
        }

        &_06 {
          grid-column: 9 / 13;
          grid-row: 3 / 5;
        }

        &_07 {
          grid-column: 9 / 13;
          grid-row: 5 / 6;
        }

        &_08 {
          grid-column: 1 / 5;
          grid-row: 6 / 8;
        }

        &_09 {
          grid-column: 1 / 5;
          grid-row: 8 / 9;
        }

        &_10 {
          grid-column: 5 / 13;
          grid-row: 6 / 7;
        }

        &_11 {
          grid-column: 5 / 13;
          grid-row: 7 / 9;
        }
      }
    }

    .swiper {
      margin-top: rem(16px);
      overflow: visible;

      @include breakpoints(xs) {
        display: block;
      }

      @include breakpoints(xl) {
        display: none;
      }

      &-slide:before {
        display: block;
        content: "";
        padding-bottom: percentage(2 / 3);
      }
    }
  }

  &-circle {

    >ul {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      margin: 0;
      list-style: none;

      @include clearfix;

      >li {

        @include breakpoints(xs) {
          width: percentage(1 / 2);
        }

        @include breakpoints(lg) {
          width: percentage(1 / 4);
        }

        >figure {
          padding: rem(20px);
          margin: 0;

          >div {
            position: relative;
            border-radius: 100rem;
            max-width: 100%;
            overflow: hidden;

            @include breakpoints(xs) {
              width: rem(110px);
              margin: 0 auto rem(15px) auto;
            }

            @include breakpoints(sm) {
              width: rem(140px);
              margin: 0 auto rem(20px) auto;
            }

            @include breakpoints(md) {
              width: rem(170px);
              margin: 0 auto rem(25px) auto;
            }

            @include breakpoints(xl) {
              width: rem(200px);
              margin: 0 auto rem(30px) auto;
            }

            @include breakpoints(xxl) {
              width: rem(230px);
              margin: 0 auto rem(35px) auto;
            }

            >img {
              position: absolute;
              display: block;
              width: 100%;
              height: 100%;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
            }

            &:after {
              display: block;
              content: "";
              padding-bottom: 100%;
            }
          }

          >figcaption {
            font-family: "YES24", sans-serif;
            text-align: center;
            padding: 0;
            margin: 0;
            word-break: keep-all;

            @include breakpoints(xs) {
              font-size: rem(17px);
            }

            @include breakpoints(sm) {
              font-size: rem(20px);
            }
          }
        }
      }
    }
  }
}