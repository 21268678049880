.timeline {

  >dl {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: rem(18px);

    >dt {

      @include breakpoints(xs) {
        width: 100%;
        font-weight: 700;
        text-align: left;
      }

      @include breakpoints(lg) {
        width: 138px;
        font-weight: normal;
        text-align: right;
      }
    }

    >dd {

      @include breakpoints(xs) {
        width: 100%;
      }

      @include breakpoints(md) {
        width: calc(100% - 138px - 20px);
      }

      >ul {
        padding: 0 0 0 rem(16px);
        margin: 0;
        list-style: none;

        &:not(:first-child) {
          margin-top: rem(4px);
        }

        >li {
          margin-bottom: rem(4px);

          &:last-child {
            margin-bottom: 0;
          }

          &:before {
            display: inline-block;
            content: "•";
            width: rem(16px);
            margin-left: rem(-16px);
            text-align: center;
          }
        }
      }
    }
  }
}