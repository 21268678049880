.home {

  &-hero {
    position: relative;
    z-index: 1;

    &:after {
      display: block;
      content: "";
      position: absolute;
      height: auto;
      background-image: url(../../images/home-hero-bg.jpg);
      background-size: cover;
      left: 0;
      right: 0;
      z-index: -1;

      @include breakpoints(xs) {
        padding-bottom: percentage(187 / 360);
        top: auto;
        bottom: 0;
      }

      @include breakpoints(sm) {
        padding-bottom: 0;
        top: percentage(390 / 1048);
        bottom: percentage(62 / 1048);
      }
    }

    >h2 {
      word-break: keep-all;
      bottom: percentage(684 / 1048);

      @include breakpoints(xs) {
        font-size: rem(32px);
        position: static;
        padding-top: rem(24px);
      }

      @include breakpoints(sm) {
        font-size: rem(18px);
        position: absolute;
        padding-top: 0;
      }

      @include breakpoints(md) {
        font-size: rem(24px);
      }

      @include breakpoints(lg) {
        font-size: rem(34px);
      }

      @include breakpoints(xl) {
        font-size: rem(44px);
      }

      @include breakpoints(xxl) {
        font-size: rem(54px);
      }


      >strong,
      >b {
        font-weight: 700;
      }

      >br {

        @include breakpoints(xs) {
          display: none;
        }

        @include breakpoints(sm) {
          display: inline;
        }
      }
    }

    >figure {
      padding: 0;
      margin: 0;
      position: relative;

      @include breakpoints(xs) {
        padding-right: 0;
      }

      @include breakpoints(sm) {
        padding-right: rem(45px);
      }

      >img {
        display: block;
        margin-left: auto;
        margin-right: 0;
        max-width: 60%;
      }

      >figcaption {
        position: absolute;
        font-weight: 700;

        @include breakpoints(xs) {
          font-size: rem(18px);
          top: auto;
          bottom: percentage(220 / 360);
          right: 55%;
        }

        @include breakpoints(sm) {
          font-size: rem(18px);
          top: percentage(268 / 1048);
          bottom: auto;
          right: rem(20px);
        }

        @include breakpoints(md) {
          font-size: rem(24px);
        }

        @include breakpoints(lg) {
          font-size: rem(28px);
        }

        @include breakpoints(xl) {
          font-size: rem(32px);
        }

        @include breakpoints(xxl) {
          font-size: rem(36px);
        }
      }
    }
  }

  &-imghero {

    @include breakpoints(xs) {
      margin-left: rem(-22px);
      margin-right: rem(-22px);
    }

    @include breakpoints(md) {
      margin-left: rem(-32px);
      margin-right: rem(-32px);
    }

    @include breakpoints(xl) {
      margin-left: rem(-40px);
      margin-right: rem(-40px);
    }

    +.horizontal {

      @include breakpoints(xs) {
        margin-top: 0;
      }

      @include breakpoints(lg) {
        margin-top: 20px;
      }
    }

    &-imgbox {

      >img {
        display: block;
        width: 100%;
      }

      &.desktop {

        @include breakpoints(xs) {
          display: none;
        }

        @include breakpoints(lg) {
          display: block;
        }
      }

      &.mobile {

        @include breakpoints(xs) {
          display: block;
        }

        @include breakpoints(lg) {
          display: none;
        }
      }
    }
  }

  &-philosophy {
    overflow: hidden;

    >.row {
      margin: 0 rem(-40px);

      >.col,
      >div[class*="col-"] {
        padding: rem(40px);
      }
    }

    &-title {

      >h2 {
        margin-bottom: 1.25rem;

        @include breakpoints(xs) {
          font-size: rem(24px);
        }

        @include breakpoints(md) {
          font-size: rem(28px);
        }

        @include breakpoints(xl) {
          font-size: rem(32px);
        }

        >br {

          @include breakpoints(xs) {
            display: none;
          }

          @include breakpoints(xxl) {
            display: inline;
          }
        }
      }

      >p {
        word-break: keep-all;

        @include breakpoints(xs) {
          font-size: rem(16px);
        }

        @include breakpoints(md) {
          font-size: rem(20px);
        }

        @include breakpoints(xl) {
          font-size: rem(24px);
        }

        >br {

          @include breakpoints(xs) {
            display: none;
          }

          @include breakpoints(xxl) {
            display: inline;
          }
        }
      }
    }

    &-bg {
      background-image: url(../../images/home-philosophy-bg.jpg);
      background-position: center;
      background-size: cover;

      @include breakpoints(xs) {
        margin-bottom: 2.5rem;
      }

      @include breakpoints(lg) {
        margin-bottom: 0;
      }

      &:after {
        display: block;
        content: "";
        padding-bottom: percentage(400 / 841);
      }
    }
  }

  &-info {
    margin-bottom: rem(40px);

    &-title {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;

      >sup {
        display: block;
        width: 100%;
        font-size: rem(16px);
        font-style: normal;
        font-weight: 400;
        vertical-align: inherit;
        line-height: normal;
        top: 0;
      }

      >h2 {
        margin: rem(6px) 0;
        word-break: keep-all;

        @include breakpoints(xs) {
          font-size: rem(24px);
        }

        @include breakpoints(md) {
          font-size: rem(28px);
        }

        @include breakpoints(xl) {
          font-size: rem(32px);
        }

        >br {

          @include breakpoints(xs) {
            display: none;
          }

          @include breakpoints(sm) {
            display: inline;
          }
        }
      }

      >div:last-child {

        @include breakpoints(xs) {
          margin-top: rem(16px);
        }

        @include breakpoints(lg) {
          margin-top: auto;
        }
      }
    }

    &-deflist {

      >div {
        overflow: hidden;

        >ul {
          padding: 0;
          margin: 0;
          list-style: none;

          >li {
            border-top: 1px solid $global-border-color;
            cursor: pointer;

            @include breakpoints(xs) {
              padding: rem(30px) 0 rem(30px) (rem(27px) + rem(40px));
            }

            @include breakpoints(lg) {
              padding: rem(30px) rem(24px) rem(30px) (rem(24px) + rem(27px) + rem(40px));
            }

            &:first-child {
              padding-top: 0;
              border-top: 0;
            }

            &:last-child {
              padding-bottom: 0;
            }

            >img {
              display: block;
              position: absolute;
              margin-left: (rem(-27px) + rem(-40px));
            }

            >h3 {
              font-size: rem(24px);
              font-weight: 700;
              margin-bottom: rem(12px);
            }

            >ol {
              padding: 0;
              margin: 0;
              list-style: none;
            }

            >ol>li,
            >p {
              padding: 0;
              margin: 0;
            }
          }
        }
      }
    }
  }

  &-figure {

    .swiper {
      // overflow: visible;
    }

    .slick {
      overflow: visible;

      &-list {
        overflow: visible
      }

      &-slide {
        padding: 0 1rem;
      }
    }

    figure {
      padding: 0;
      margin: 0;
      overflow: visible;

      >div {
        position: relative;
        padding-bottom: percentage(2 / 3);
        background-color: black;

        >img {
          display: block;
          position: absolute;
          width: 100%;
          min-height: 100%;
          top: -9999px;
          bottom: -9999px;
          margin: auto;
        }
      }

      >figcaption {
        margin-top: rem(8px);
      }
    }
  }

  &-banner {}

  &-direction {

    &-title {

      >h2 {
        font-weight: 900;
        padding-top: rem(32px);
        margin-bottom: rem(30px);
      }

      >dl {

        >dt {
          font-size: rem(24px);
          font-weight: 700;
          margin-bottom: rem(12px);
        }

        >dd {
          margin-bottom: rem(42px);
        }

        >*:last-child {
          margin-bottom: 0;
        }
      }
    }

    &-content {

      >.iframe {
        position: relative;
        padding-bottom: percentage(360 / 920);

        >iframe {
          position: absolute;
          width: 100%;
          height: 100%;
        }
      }

      >.image {

        >img {
          max-width: 100%;
        }
      }
    }
  }

  &-channelk {

    &-title {

      @include breakpoints(lg) {
        border-right: 1px solid $global-border-color;
        margin-bottom: 0;
      }

      @include breakpoints(xs) {
        border-right: 0;
        margin-bottom: rem(16px);
      }

      >h2 {
        font-size: rem(32px);
        font-weight: 900;
      }

      >h3 {
        font-size: rem(24px);
        font-weight: 700;
      }

      >p {
        padding: 0;
        margin: 0;
      }
    }

    &-content {

      .gallery {

        >ul {

          >li {

            >a {

              .meta {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  &-contact {
    padding: rem(24px) 0;

    >h2 {
      font-weight: normal;
      margin-bottom: rem(10px);

      @include breakpoints(xs) {
        font-size: rem(20px);
      }

      @include breakpoints(sm) {
        font-size: rem(26px);
      }

      @include breakpoints(md) {
        font-size: rem(32px);
      }

      @include breakpoints(lg) {
        font-size: rem(38px);
      }

      @include breakpoints(xl) {
        font-size: rem(44px);
      }

      @include breakpoints(xxl) {
        font-size: rem(50px);
      }
    }

    >p {
      font-size: rem(24px);
      margin-bottom: 0;

      @include breakpoints(xs) {
        font-size: rem(18px);
      }

      @include breakpoints(sm) {
        font-size: rem(19px);
      }

      @include breakpoints(md) {
        font-size: rem(20px);
      }

      @include breakpoints(lg) {
        font-size: rem(21px);
      }

      @include breakpoints(xl) {
        font-size: rem(22px);
      }

      @include breakpoints(xxl) {
        font-size: rem(23px);
      }
    }

    >div {

      >a {
        display: inline-block;
        font-size: rem(72px);
        font-weight: 100;
        margin-left: auto;
        margin-right: 0;
        font-style: normal;
        color: inherit;
        text-decoration: none;

        &:after {
          display: block;
          content: "";
          width: 100%;
          height: 10px;
          background-color: color(primary);
        }

        @include breakpoints(xs) {
          font-size: rem(32px);
        }

        @include breakpoints(sm) {
          font-size: rem(40px);
        }

        @include breakpoints(md) {
          font-size: rem(48px);
        }

        @include breakpoints(lg) {
          font-size: rem(56px);
        }

        @include breakpoints(xl) {
          font-size: rem(66px);
        }

        @include breakpoints(xxl) {
          font-size: rem(72px);
        }
      }
    }
  }
}