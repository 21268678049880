.container {
  width: auto;

  @include breakpoints(xs) {
    padding-left: rem(16px);
    padding-right: rem(16px);
    margin-left: 6px;
    margin-right: 6px;
  }

  @include breakpoints(sm) {
    padding-left: rem(16px);
    padding-right: rem(16px);
    margin-left: auto;
    margin-right: auto;
  }

  @include breakpoints(md) {
    padding-left: rem(32px);
    padding-right: rem(32px);
  }

  @include breakpoints(xl) {
    padding-left: rem(40px);
    padding-right: rem(40px);
  }

  &.is-full {

    @include breakpoints(xxl) {
      padding-left: rem(40px);
      padding-right: rem(40px);
      max-width: none;
    }
  }
}

.row {

  &.vertical-wrap {
    margin-left: rem(-28px);
    margin-right: rem(-28px);

    >.col,
    >[class*="col-"] {
      padding-left: rem(28px);
      padding-right: rem(28px);

      &.vertical {
        flex: 0 auto;

        padding: 0 !important;

        &:before {
          display: block;
          content: "";
          width: 1px;
          height: 100%;
          background-color: $global-border-color;

          @include breakpoints(xs) {
            display: none;
          }

          @include breakpoints(lg) {
            display: block;
          }
        }

        &:after {
          display: block;
          content: "";
          height: 1px;
          background-color: $global-border-color;
          margin-top: rem(40px);
          margin-bottom: rem(40px);

          @include breakpoints(xs) {
            display: block;
            margin-left: rem(-16px);
            margin-right: rem(-16px);
          }

          @include breakpoints(lg) {
            display: block;
            margin-left: rem(-32px);
            margin-right: rem(-32px);
          }

          @include breakpoints(lg) {
            display: none;
          }
        }
      }
    }
  }
}

@each $key, $value in $grid-breakpoints {

  @include breakpoints($key) {

    .d-block-#{$key} {
      display: block;
    }

    .d-none-#{$key} {
      display: none;
    }

    .d-inline-#{$key} {
      display: inline;
    }

    .d-inline-block-#{$key} {
      display: inline-block;
    }

    .d-flex-#{$key} {
      display: flex;
    }

    .flex-wrap-#{$key} {
      flex-wrap: wrap;
    }

    .flex-nowrap-#{$key} {
      flex-wrap: nowrap;
    }
  }
}