.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;

  a {
    display: block;

    img {
      max-width: 90vw;
      max-height: 90vh;
    }
  }
}