.numbering {
  display: flex;
  justify-content: center;

  a, span {
    display: block;
    padding: rem(6px);
    color: inherit;
    text-decoration: none;
  }

  span {
    opacity: 0.25;
  }

  a {

    &.is-active {
      font-weight: 700;
    }
  }

  > ul {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;

    > li {
      position: relative;
      padding: 0 rem(6px);
        
      &:after {
        display: block;
        content: "";
        position: absolute;
        width: 1px;
        height: 0.75em;
        top: 0;
        bottom: 0;
        margin: auto;
        right: 0;
        background-color: $global-border-color;
      }

      &:last-child {

        &:after {
          display: none;
        }
      }
    }
  }
}