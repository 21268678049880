.definition {
  overflow: hidden;

  >dl {
    margin: rem(-40px);

    @include breakpoints(xs) {
      display: block;
    }

    @include breakpoints(lg) {
      display: flex;
    }

    >dt,
    >dd {
      padding: rem(40px);
    }

    >dt {
      display: flex;

      @include breakpoints(xs) {
        flex-direction: row;
        justify-content: space-between;
        width: auto;
        border-right: 0;
        padding-bottom: 0;
      }

      @include breakpoints(lg) {
        flex-direction: column;
        justify-content: flex-start;
        width: rem(280px);
        border-right: 1px solid $global-border-color;
        padding-bottom: rem(40px);
      }

      .definition-description,
      .definition-term,
      strong,
      b {
        display: block;
        font-size: rem(24px);
        font-style: normal;
        font-weight: 400;
      }

      .definition-description {

        @include breakpoints(xs) {
          margin-bottom: 0;
        }

        @include breakpoints(lg) {
          margin-bottom: rem(14px);
        }
      }

      .definition-term,
      strong,
      b {
        font-weight: 700;
      }

      .btn {

        @include breakpoints(xs) {
          margin-top: 0;
        }

        @include breakpoints(lg) {
          margin-top: 1.25rem;
        }
      }
    }

    >dd {

      @include breakpoints(xs) {
        width: auto;
      }

      @include breakpoints(lg) {
        width: calc(100% - rem(280px));
      }
    }
  }
}