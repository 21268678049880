.btn {

  &-icon {
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-weight: 700;

    @include breakpoints(xs) {
      font-size: rem(20px);
    }

    @include breakpoints(sm) {
      font-size: rem(22px);
    }

    @include breakpoints(md) {
      font-size: rem(24px);
    }

    &-jumbo {

      @include breakpoints(xs) {
        padding: rem(20px);
      }

      @include breakpoints(sm) {
        padding: rem(26px);
      }

      @include breakpoints(md) {
        padding: rem(32px);
      }
    }

    >em {
      display: block;
      font-style: normal;
    }

    >span {
      display: block;
      margin-left: rem(8px);
    }
  }
}