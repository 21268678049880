.l {

  &-navigation {
    height: 0;
    overflow: hidden;
    transition: height 0.5s ease-in-out;

    a {
      text-decoration: none;
      color: inherit;
    }

    >ul {
      padding: 0;
      margin: 0;
      list-style: none;

      >li {

        >a {
          display: block;
          padding: rem(12px);
          text-align: center;
          border-bottom: 1px solid $global-border-color;
        }

        >ul {
          padding: rem(12px);
          margin: 0;
          background-color: #f5f5f5;
          list-style: none;
          border-bottom: 1px solid $global-border-color;

          &[data-length="0"] {
            display: none !important;
          }

          >li {
            margin: rem(4px) 0;

            >a {
              font-size: rem(14px);
              display: block;
              text-align: center;
              border-radius: 3px;
              transition: 0.3s;

              &:hover,
              &:focus {
                background-color: rgba(black, 0.05);
              }
            }

            &.is-active {

              >a {
                color: color(primary);
              }
            }
          }
        }

        &.is-active {

          >a {
            color: color(primary);
          }
        }
      }
    }
  }
}