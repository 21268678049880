.banner {

  >a {
    display: block;

    @include breakpoints(xs) {
      margin-left: rem(-16px);
      margin-right: rem(-16px);
    }

    @include breakpoints(md) {
      margin-left: rem(-32px);
      margin-right: rem(-32px);
    }

    @include breakpoints(xl) {
      margin-left: rem(-40px);
      margin-right: rem(-40px);
    }

    >img {
      display: block;
      width: 100%;

      &.desktop {

        @include breakpoints(xs) {
          display: none;
        }

        @include breakpoints(md) {
          display: block;
        }
      }

      &.mobile {

        @include breakpoints(xs) {
          display: block;
        }

        @include breakpoints(md) {
          display: none;
        }
      }
    }
  }
}