.list {

  &-disc {
    padding-left: 1em;
    list-style: none;

    &:last-child {
      margin-bottom: 0;
    }

    >li {
      margin-bottom: 0.25em;

      >h1,
      >h2,
      >h3,
      >h4,
      >h5,
      >h6 {
        font-size: inherit;
        line-height: inherit;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:before {
        display: block;
        content: "" !important;
        /* float: left; */
        position: absolute;
        width: 0.25em !important;
        height: 0.25em !important;
        background-color: color(dark);
        margin-left: -1em;
        margin-top: 0.5em + (0.25em / 2);
        border-radius: $border-radius;
      }
    }
  }

  &-decimal {
    padding-left: 1em;
    list-style: none;
    counter-reset: list_decimal;

    &:last-child {
      margin-bottom: 0;
    }

    >li {
      margin-bottom: 0.25em;

      &:last-child {
        margin-bottom: 0;
      }

      &:before {
        display: block;
        counter-increment: list_decimal 1;
        content: counter(list_decimal) '.';
        /* float: left; */
        position: absolute;
        margin-left: -1em;
        padding-right: 0.25em;
        width: 1em;
      }
    }
  }

  &-share {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: center;

    >li {
      padding: 0 0.5rem;
      margin: 0;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      >a {
        display: block;
        border: 1px solid #ccc;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}