// fonts
$font-size-base: 1rem;
$font-family-sans-serif: "SUIT", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-monospace:  SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;

// Theme colors
$theme-colors: (
  primary: #18c6b5,
  secondary: #6c757d,
  link: #0056b3,
  success: #28a745,
  danger: #dc3545,
  warning: #ffc107,
  info: #17a2b8,
  light: #f8f9fa,
  dark: #343a40,
  muted: #6c757d,
  white: #fff
) !default;

// Breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1260px,
  xxl: 1480px
) !default;

// Container max-width
$container-max-widths: (
  sm: 576px - (40px * 2),
  md: 768px - (40px * 2),
  lg: 992px - (40px * 2),
  xl: 1260px - (40px * 2),
  xxl: 1480px - (40px * 2)
) !default;

$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer / 4,
  2: $spacer / 2,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 4.5,
  7: $spacer * 6,
  8: $spacer * 2,
) !default;

// trig
$pi: 3.14159265359;
$_precision: 10;

// table
$table-cell-padding-x: 1rem;
$table-cell-padding-y: 1rem;

// global
$global-border-color: #636363;

// grid
$grid-gutter-width: 1.5rem;

// l-header
$header-padding-spacing: 1.25rem;
$header-navigation-spacing: 2.5rem;
$header-sns-spacing: 0.75rem;

// hamburger
$hamburger-line-width: 24px;
$hamburger-line-height: 3px;
$hamburger-line-gutter: 7px;

// gallery
$gallery-gutter-x: 3.5rem;
$gallery-gutter-y: 1.75rem;