.doctor {

  &-opening {
    padding-top: rem(40px);

    .row {
      align-items: flex-end;
    }

    .title {
      padding-bottom: rem(40px);

      >h1 {
        font-weight: 400;
        margin-top: rem(10px);

        @include breakpoints(xs) {
          font-size: rem(24px);
          margin-bottom: rem(30px);
        }

        @include breakpoints(md) {
          font-size: rem(28px);
          margin-bottom: rem(40px);
        }

        @include breakpoints(xl) {
          font-size: rem(32px);
          margin-bottom: rem(50px);
        }

        >strong {
          font-size: 1.6em;
          font-weight: 700;
          padding-right: 0.25em;
        }
      }

      >h2 {
        font-weight: 400;
        color: color(primary);
        margin-bottom: rem(20px);
        word-break: keep-all;

        @include breakpoints(xs) {
          font-size: rem(24px);
        }

        @include breakpoints(md) {
          font-size: rem(28px);
        }

        @include breakpoints(xl) {
          font-size: rem(32px);
        }

        >br {

          @include breakpoints(xs) {
            display: none;
          }

          @include breakpoints(sm) {
            display: inline;
          }
        }
      }

      >p {
        word-break: keep-all;

        @include breakpoints(xs) {
          font-size: rem(16px);
        }

        @include breakpoints(md) {
          font-size: rem(20px);
        }

        @include breakpoints(xl) {
          font-size: rem(24px);
        }

        >br {

          @include breakpoints(xs) {
            display: none;
          }

          @include breakpoints(sm) {
            display: inline;
          }
        }
      }
    }

    .image {

      >img {
        display: block;
        margin-left: auto;
        max-width: 90%;
      }
    }
  }

  &-definition {

    >ul {
      padding: 0;
      margin: 0;
      list-style: none;

      >li {
        padding: rem(40px) 0;
      }

      .definition {

        >dl {

          >dt {
            font-size: 1.375rem;
            display: flex;
            justify-content: start;
            flex-direction: column-reverse;

            >em,
            >span {
              font-size: inherit;
            }
          }
        }
      }
    }
  }

  &-gallery {
    padding: rem(40px) 0;

    .slick {

      &-list {
        overflow: visible;
      }

      &-slide {
        padding: 0 rem(16px);

        img {
          display: block;
          height: 365px;
        }
      }
    }
  }
}