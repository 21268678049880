.l {

  &-header {
    font-family: 'Yes Gothic', sans-serif;
    color: black;
    border-top: 1px solid $global-border-color;
    border-bottom: 1px solid $global-border-color;

    >.container {
      position: relative;
      display: flex;
      align-content: stretch;
      align-items: center;
      padding-top: $header-padding-spacing;
      padding-bottom: $header-padding-spacing;

      &:before {
        top: 0;
      }

      &:after {
        bottom: 0;
      }

      >.hamburger {
        margin-left: auto;

        @include breakpoints(xs) {
          display: block;
        }

        @include breakpoints(lg) {
          display: none;
        }
      }
    }

    &-logo {
      display: block;
      overflow: hidden;

      &:before {
        display: block;
        content: "케이 구강악안면외과 의원";
        text-indent: -9999px;
        height: 0;
        box-sizing: content-box;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
      }

      @include breakpoints(xs) {
        background-image: url(../../images/l-header-logo-mobile.svg);
        width: 100%;
        max-width: 142px;

        &:before {
          padding-bottom: percentage(49 / 142);
        }
      }

      @include breakpoints(lg) {
        background-image: url(../../images/l-header-logo-desktop.svg);
        width: 100%;
        max-width: 346px;

        &:before {
          padding-bottom: percentage(48 / 346);
        }
      }
    }

    &-nav {
      list-style: none;
      padding: $header-padding-spacing 0;
      margin: (0 - $header-padding-spacing) 0;
      margin-left: auto;

      @include breakpoints(xs) {
        display: none;
      }

      @include breakpoints(lg) {
        display: block;
        margin-right: 0;
      }

      @include breakpoints(xl) {
        margin-right: ($header-navigation-spacing / 2);
      }

      >ul {
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0;
        height: 100%;
        align-items: center;

        @include breakpoints(lg) {
          margin: 0 ($header-navigation-spacing / -3);
        }

        @include breakpoints(xl) {
          margin: 0 ($header-navigation-spacing / -2);
        }


        >li {

          @include breakpoints(lg) {
            padding: 0 ($header-navigation-spacing / 3);
          }

          @include breakpoints(xl) {
            padding: 0 ($header-navigation-spacing / 2);
          }

          >a {
            color: inherit;
            text-decoration: inherit;
          }

          >ul {
            display: none !important; // dropdown 기능 사용할 경우 !important 제거
            padding: 0;
            margin: 0;
            list-style: none;
            position: absolute;
            background-color: white;
            box-shadow: $box-shadow;
            z-index: 99;

            >li {
              color: color(dark);
              transition: 0.1s;

              >a {
                font-size: rem(14px);
                display: block;
                padding: rem(12px) rem(16px);
                color: inherit;
                text-decoration: none;
              }

              &.is-active {
                background-color: color(primary);
                color: white;
              }

              &:hover {
                background-color: lighten(color(primary), 2%);
                color: white;
              }
            }
          }

          &.is-active {
            color: color(primary);
          }

          &:hover {

            >ul {
              display: block;
            }
          }
        }
      }
    }

    &-sns {
      padding-left: ($header-sns-spacing / 2);
      border-left: 1px solid $global-border-color;
      height: 24px;

      @include breakpoints(xs) {
        display: none;
      }

      @include breakpoints(xl) {
        display: block;
      }


      >ul {
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0;
        height: 100%;
        align-items: center;
        margin-right: ($header-sns-spacing / -2);

        >li {
          padding: 0 ($header-sns-spacing / 2);

          >a {
            display: flex;
            width: 24px;
            height: 24px;
            justify-content: center;
            align-content: center;

            >img {
              max-width: 100%;
              max-height: 100%;
            }
          }
        }
      }
    }
  }
}